import { useEffect, useState } from "react";
import { styled } from "styled-components";
import Spinner from "../components/spinner";

const Splash = () => {
  const [message, setMessage] = useState("Just a moment");

  useEffect(() => {
    setTimeout(() => {
      setMessage("Welcome to IT Consults");
    }, 1500);
  }, []);

  return (
    <Container>
      <Spinner size="large" />
      <Message>{message}</Message>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-content: center;
  align-items: center;
  align-content: center;

  background: rgb(238, 174, 202);
  background: radial-gradient(
    circle,
    rgba(238, 174, 202, 1) 0%,
    rgba(148, 187, 233, 1) 100%
  );

  /* background: rgb(48, 48, 48);
  background: radial-gradient(
    circle,
    rgba(48, 48, 48, 1) 0%,
    rgba(116, 116, 116, 1) 100%
  ); */
`;

const SpinnerContainer = styled.div`
  width: 5em;
  height: 5em;
  cursor: not-allowed;
  border-radius: 50%;
  border: 2px solid #444;
  box-shadow: -10px -10px 10px #6359f8, 0px -10px 10px 0px #9c32e2,
    10px -10px 10px #f36896, 10px 0 10px #ff0b0b, 10px 10px 10px 0px#ff5500,
    0 10px 10px 0px #ff9500, -10px 10px 10px 0px #ffb700;
  animation: rot55 0.7s linear infinite;

  @keyframes rot55 {
    to {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerPin = styled.div`
  border: 2px solid #444;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Message = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  color: #fff;

  @media screen and (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

export default Splash;
