import { styled } from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

export const Nav = styled.nav`
  background: ${({ scrollnav }) => (scrollnav ? "#000" : "transparent")};
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1100px;
`;

export const NavLogo = styled(RouterLink)`
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  text-decoration: none;
  transition: all ease 0.2s;

  &:hover {
    color: #ff6341;
  }

  * {
    margin: 0;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin: auto 0;
  margin-right: -22px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
`;

export const NavLinks = styled(ScrollLink)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.active {
    border-bottom: 3px solid #ff6341;
  }

  &:hover {
    color: #ff6341;
  }
`;
export const NavRouterLink = styled(RouterLink)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &.active {
    border-bottom: 3px solid #ff6341;
  }

  &:hover {
    color: #ff6341;
  }
`;
export const NavBtn = styled.nav`
  display: flex;
  align-items: center;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavBtnLink = styled(RouterLink)`
  border-radius: 50px;
  text-decoration: none;
  background: #ff6341;
  white-space: nowrap;
  padding: 10px 22px;
  color: #010606;
  font-size: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-weight: 900;

  &:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #ff6341;
  }
`;

export const ProfileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const NavbarAvatar = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
`;

export const SubMenuWrapper = styled.div`
  position: absolute;
  top: 70%;
  right: 10%;
  width: 70vw;
  max-width: 320px;
  max-height: 400px;
  overflow: hidden;
  animation: load 1s ease-in;

  @keyframes load {
    0% {
      opacity: 0.1;
    }

    100% {
      opacity: 1;
    }
  }
`;

export const SubMenu = styled.div`
  background-color: #fafafa;
  padding: 20px;
  margin: 10px;
  border-radius: 4px;
  border: 2px solid #ff6341;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const UserAvatar = styled.img`
  width: 32px;
  border-radius: 50%;
`;

export const UserName = styled.h3`
  font-weight: 600;
  font-size: 18px;
`;

export const HorizontalLine = styled.hr`
  border: 0;
  height: 1px;
  width: 100%;
  background-color: #ccc;
  margin: 10px 0;
`;

export const SubMenuLink = styled(RouterLink)`
  display: flex;
  align-items: center;
  gap: 7px;
  text-decoration: none;
  text-transform: capitalize;
  color: #525252;
  margin: 12px 0;
  font-size: 14px;
  font-weight: 600;

  &:active {
    transition-duration: 250ms;
    transform: scale(0.97);
  }

  &:hover,
  &:hover > svg {
    transition-duration: 250ms;
    color: #ff6341;
    fill: #ff6341;
  }

  &:hover svg:last-child {
    transition-duration: 176ms;
    transform: translateX(5px);
  }
`;

export const SubMenuTitle = styled.p`
  width: 100%;
  margin: 0;
`;
