import { useState } from "react";
import { styled } from "styled-components";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const Step2 = () => {
  const [showAppData, setShowAppData] = useState(false);

  return (
    <FormControl>
      <RadioGroup defaultValue="no" name="existingSoftware">
        <FormControlLabel
          value="no"
          defaultChecked
          control={<Radio />}
          label="I don't have an existing website or application."
          onClick={() => setShowAppData(false)}
        />

        <FormControlLabel
          value="yes"
          control={<Radio />}
          label="I have an existing website or application."
          onClick={() => setShowAppData(true)}
        />
      </RadioGroup>

      {showAppData && <span>App Data</span>}
    </FormControl>
  );
};

const Container = styled.div``;

export default Step2;
