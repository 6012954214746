const tokenKey = "itconsultsAccountToken3249324802";

export const storeToken = (token) => {
  localStorage.setItem(tokenKey, token);
};

export const getToken = () => {
  const token = localStorage.getItem(tokenKey);
  const bearerToken = "Bearer " + token;
  return token ? bearerToken : "";
};

export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};

const functions = {
  getToken,
  removeToken,
  storeToken,
};

export default functions;
