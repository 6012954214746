import { useEffect, useState } from "react";
import Footer from "../components/footer";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import useAuth from "../auth/useAuth";
import EmailInput from "../components/inputs/EmailInput";
import NameInput from "../components/inputs/NameInput";
import useDateTimer from "../hooks/useDateTimer";
import usersApi from "../api/user/users";
import Spinner from "../components/spinner";
import { toast } from "react-toastify";
import { animateScroll } from "react-scroll";

const Profile = () => {
  const { user, login } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { value: lastLogin } = useDateTimer(user.lastLogin);
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState({
    name: user.name,
    email: user.email,
    error: "",
    submitting: false,
  });

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const handleKeyChange = (key) => (e) => {
    setContext({
      ...context,
      error: "",
      [key]: e.target.value,
    });
  };

  const isFormDisabled = () => {
    return user.name === context.name && user.email === context.email;
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleEditProfile = async (e) => {
    e.preventDefault();
    let error = "";

    try {
      setContext({ ...context, submitting: true });

      const res = await usersApi.common.updateProfile(context);
      const { user, token } = res.data;

      login(user, token);
    } catch (err) {
      error = err?.response?.data?.message?.en || "Network Error";
    } finally {
      setContext({ ...context, submitting: false, error });
    }
  };

  const handleDeleteAccount = async () => {
    try {
      setLoading(true);

      const res = await usersApi.common.requestAccountDeletion();
      const message = res.data;

      setLoading(false);
      toast.success(message.en, { position: "top-left" });
    } catch (err) {
      setLoading(false);
      const error = err?.response?.data?.message?.en || "Network Error";
      toast.error(error, { position: "top-left" });
    }
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />

      <ContentContainer>
        <TopContainer>
          <AvatarContainer>
            <AvatarImage src={user.avatarURL || "/images/default-user.jpg"} />

            <UserName>{user.name}</UserName>

            {loading ? (
              <SpinnerContainer>
                <Spinner size="small" />
              </SpinnerContainer>
            ) : (
              <DeleteAccount onClick={handleDeleteAccount}>
                Delete Account
              </DeleteAccount>
            )}
          </AvatarContainer>
        </TopContainer>

        <InputsContainer onSubmit={handleEditProfile}>
          <InputsTitle>Profile info</InputsTitle>

          <LastLogin>Last login since {lastLogin} ago</LastLogin>

          <NameInput value={context.name} onChange={handleKeyChange("name")} />

          <EmailInput
            value={context.email}
            onChange={handleKeyChange("email")}
          />

          {!!context.error && <Error>{context.error}</Error>}

          {context.submitting ? (
            <SpinnerContainer>
              <Spinner size="small" />
            </SpinnerContainer>
          ) : (
            <Button disabled={isFormDisabled()} onClick={handleEditProfile}>
              Edit
            </Button>
          )}
        </InputsContainer>
      </ContentContainer>

      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const ContentContainer = styled.main`
  margin-top: 80px;
  padding: 50px 80px;
  background-color: #fefefe;
  display: flex;
  gap: 45px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const AvatarImage = styled.img`
  border-radius: 50%;
  width: 100px;
  object-fit: cover;
  cursor: pointer;
`;

const UserName = styled.h1`
  font-size: 15px;
  font-weight: 600;
`;

const DeleteAccount = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: #f00;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    transition-duration: 176ms;
    color: #000;
  }
`;

const InputsContainer = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  width: 80vw;
  max-width: 400px;
`;

const InputsTitle = styled.h2`
  font-weight: bold;
  text-transform: capitalize;
`;

const LastLogin = styled.p`
  font-size: 14px;
  font-weight: 600;
`;

const Button = styled.button`
  border-radius: 8px;
  background-color: ${({ disabled }) => (disabled ? "#747474" : "#ff6341")};
  white-space: nowrap;
  padding: 10px 25px;
  color: #fff;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 600;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:active {
    transform: scale(0.95);
  }
`;

const Error = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: #f00;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export default Profile;
