import "./RegisterForm.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import useAuth from "../../auth/useAuth";
import Spinner from "../spinner";
import authApi from "../../api/user/auth";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const RegisterForm = () => {
  const { login } = useAuth();
  const [context, setContext] = useState({ name: "", email: "", password: "" });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [approved, setApproved] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);

      // TEST
      setTimeout(() => {
        setLoading(false);
      }, 1500);

      const res = await authApi.registerWithEmail(context);
      const { user, token } = res.data;

      setLoading(false);
      login(user, token);
      navigate("/");
    } catch (err) {
      setLoading(false);
      const error = err.response?.data?.message?.en || "Network Error";
      setError(error);
    }
  };

  const handleContinueWithGoogle = async () => {
    try {
      setLoading(true);

      const res = await authApi.registerWithGoogle();
      const { user, token } = res.data;

      login(user, token);
      navigate("/");
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const error = err.response?.data?.message?.en || "Network Error";
      setError(error);
    }
  };

  const handleKeyChange = (key) => (event) => {
    try {
      setError("");
      setContext({ ...context, [key]: event.target.value });
    } catch (err) {}
  };

  return (
    <div className="form-container">
      <p className="title">Register</p>

      <form className="form" onSubmit={onSubmit}>
        <div className="input-group">
          <label htmlFor="name">Full Name *</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Your full name"
            value={context.name}
            onChange={handleKeyChange("name")}
          />
        </div>

        <div className="input-group">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            value={context.email}
            onChange={handleKeyChange("email")}
          />
        </div>

        <div className="input-group">
          <label htmlFor="password">Password *</label>
          <input
            type="password"
            name="password"
            id="password"
            placeholder="Enter your password"
            value={context.password}
            onChange={handleKeyChange("password")}
          />

          {!!error && (
            <div style={{ fontSize: "14px", color: "#f00" }}>{error}</div>
          )}
        </div>

        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                color="success"
                checked={approved}
                onChange={() => setApproved(!approved)}
              />
            }
            label="I agree to the Terms of Service and Privacy Policy."
          />
        </FormGroup>

        <div />

        {loading ? (
          <div
            className="row d-flex justify-content-center"
            style={{ marginTop: "20px", marginBottom: "40px" }}
          >
            <Spinner size="small" />
          </div>
        ) : (
          <>
            {" "}
            <button
              className="sign"
              onClick={onSubmit}
              disabled={loading || !approved}
            >
              Continue
            </button>
            <button
              className="sign white"
              onClick={handleContinueWithGoogle}
              disabled={loading || !approved}
            >
              <FcGoogle size={24} /> Continue with Google
            </button>
          </>
        )}
      </form>

      <p className="signup">
        Already have an account? <Link to="/signin">Login</Link>
      </p>
    </div>
  );
};

export default RegisterForm;
