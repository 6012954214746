import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  Price,
  ViewDetailsBtn,
} from "./ServicesElements";
import services from "../../static/services";

const Services = () => {
  return (
    <ServicesContainer id="services">
      <ServicesH1>Our Services</ServicesH1>

      <ServicesWrapper>
        {services
          .sort((a, b) => a.price - b.price)
          .map((service) => (
            <ServicesCard key={service.id}>
              <ServicesIcon src={service.imageURL} alt={service.title} />

              <ServicesH2>{service.title}</ServicesH2>

              <ServicesP>{service.description}</ServicesP>

              <Price>${service.price.toFixed(2)}</Price>

              <ViewDetailsBtn to={`/service/${service.id}/details`}>
                View details
              </ViewDetailsBtn>
            </ServicesCard>
          ))}
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
