import client from "../client";

const getStripePublishableKey = async () => {
  const cacheMins = 10;
  return await client.get("/payment/config", {}, cacheMins);
};

const functions = {
  getStripePublishableKey,
};

export default functions;
