import axios from "axios";

const getVisitorData = async () => {
  try {
    const currentDate = new Date();

    const res = await axios.get("https://geolocation-db.com/json/");

    const {
      country_code,
      country_name,
      city,
      postal,
      latitude,
      longitude,
      IPv4,
      state,
    } = res.data;

    const visitDate = currentDate.toDateString();
    const visitTime = currentDate.toTimeString();

    return {
      country_code,
      country_name,
      city,
      postal,
      latitude,
      longitude,
      IPv4,
      state,
      visitDate,
      visitTime,
    };
  } catch (err) {
    return {
      country_code: null,
      country_name: null,
      city: null,
      postal: null,
      latitude: null,
      longitude: null,
      IPv4: null,
      state: null,
      visitDate: null,
      visitTime: null,
    };
  }
};

export default getVisitorData;
