import { useEffect, useState } from "react";
import { useParams, useNavigate, Link as RouterLink } from "react-router-dom";
import Footer from "../components/footer";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import services from "../static/services";
import Spinner from "../components/spinner";
import { animateScroll } from "react-scroll";
import useAuth from "../auth/useAuth";

const ServiceDetails = () => {
  const { user } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [service, setService] = useState(services[0]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    animateScroll.scrollToTop();
    const startDate = Date.now();

    const service = services.find((service) => service.id === params.serviceId);
    if (!service) {
      navigate("/not-found");
    } else {
      setService(service);
    }

    const endDate = Date.now();
    const timeElapsed = endDate - startDate;
    const splashTime = 3000 - timeElapsed;

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, splashTime);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [params]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />

      <ContentContainer>
        {loading && (
          <SpinnerContainer>
            <Spinner size="large" />
          </SpinnerContainer>
        )}

        {!loading && (
          <ServiceContainer>
            <InfoRow>
              <Image src={service.imageURL} alt={service.title} />

              <AboutContainer>
                <Title>
                  {service.title} <PriceBadge>${service.price}</PriceBadge>
                </Title>

                <Description>{service.longDescription}</Description>
              </AboutContainer>
            </InfoRow>

            <FeaturesContainer>
              <Title>What we provide here?</Title>

              <FeaturesList>
                {service.features.map((feature, index) => (
                  <FeatureItem key={feature + index}>{feature}</FeatureItem>
                ))}
              </FeaturesList>
            </FeaturesContainer>

            <FeaturesContainer>
              <Title>How we provide this service?</Title>

              <FeaturesList>
                <FeatureItem>By video meeting.</FeatureItem>
                <FeatureItem>By audio call.</FeatureItem>
                <FeatureItem>By PDF file sent to your email.</FeatureItem>
              </FeaturesList>
            </FeaturesContainer>

            <FeaturesContainer>
              <Title>After payment, when you receive service?</Title>

              <FeaturesList>
                <FeatureItem>
                  When video meeting, you will receive it within one day.
                </FeatureItem>

                <FeatureItem>
                  When audio call, you will receive it within one day.
                </FeatureItem>

                <FeatureItem>
                  When PDF file, you will receive it within one day.
                </FeatureItem>
              </FeaturesList>
            </FeaturesContainer>

            <RequestServiceContainer>
              <Title>Still intersted?</Title>
              <ButtonLink
                to={user ? `/service/${service.id}/request` : "/signin"}
                dark
                primary
              >
                Let's go!
              </ButtonLink>
            </RequestServiceContainer>
          </ServiceContainer>
        )}
      </ContentContainer>

      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const ContentContainer = styled.main`
  margin-top: 80px;
  padding: 40px 20px;
  background-color: #fefefe;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ServiceContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 768px) {
    padding: 0;
    gap: 25px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 35px;
  }
`;

const Image = styled.img`
  width: 40vw;
  max-width: 280px;
  object-fit: cover;

  @media screen and (max-width: 480px) {
    width: 90vw;
  }
`;

const AboutContainer = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media screen and (max-width: 480px) {
    gap: 0;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const PriceBadge = styled.span`
  background-color: #ff6341;
  color: #fff;
  opacity: 0.8;
  border-radius: 4px;
  font-size: 12px;
  padding: 1px 4px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 15px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const FeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const FeaturesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const FeatureItem = styled.li`
  font-size: 15px;
  font-weight: 500;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

const RequestServiceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const ButtonLink = styled(RouterLink)`
  width: fit-content;
  text-transform: capitalize;
  border-radius: 50px;
  background: ${({ primary }) => (primary ? "#ff6341" : "#010606")};
  white-space: nowrap;
  padding: ${({ big }) => (big ? "14px 48px" : "12px 30px")};
  color: ${({ dark }) => (dark ? "#010606" : "#fff")};
  font-size: ${({ fontBig }) => (fontBig ? "20px" : "16px")};
  outline: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 600;

  &:hover {
    background: ${({ primary }) => (primary ? "#000" : "#ff6341")};
    color: #fff;
  }
`;

export default ServiceDetails;
