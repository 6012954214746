import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarRoute,
  SideBtnWrap,
  SidebarWrapper,
  SidebarMenu,
  SidebarScrollLink,
  SidebarRouterLink,
} from "./SidebarElements";
import useAuth from "../../auth/useAuth";

export const Sidebar = ({ isOpen, toggle }) => {
  const { user } = useAuth();

  return (
    <SidebarContainer isopen={isOpen ? "true" : ""} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>

      <SidebarWrapper>
        <SidebarMenu>
          <SidebarScrollLink href="/" to="about" onClick={toggle}>
            About
          </SidebarScrollLink>

          <SidebarScrollLink href="/" to="discover" onClick={toggle}>
            Discover
          </SidebarScrollLink>

          <SidebarScrollLink href="/" to="services" onClick={toggle}>
            Services
          </SidebarScrollLink>

          <SidebarRouterLink to="/privacy-policy" onClick={toggle}>
            Privacy Policy
          </SidebarRouterLink>

          <SidebarRouterLink to="/terms" onClick={toggle}>
            Terms of Service
          </SidebarRouterLink>
        </SidebarMenu>

        <SideBtnWrap>
          <SidebarRoute to={user ? "/profile" : "/signin"} onClick={toggle}>
            {user ? "Profile" : "Sign In"}
          </SidebarRoute>
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};
