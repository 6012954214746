import { useEffect, useState } from "react";
import HeroSection from "../components/heroSection";
import InfoSection from "../components/infoSection";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import InfoSectionLight from "../components/infoSectionLight";
import Services from "../components/services";
import Footer from "../components/footer";
import { animateScroll } from "react-scroll";

const Home = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />

      <HeroSection />

      <InfoSection
        image="/images/design-notes.svg"
        id="about"
        subtitle="About us"
        title="HOW WE CAN HELP YOUR BUSINESS GOAL"
        text="Choosing a suitable theme for your business isn’t hard if you know what to look for. A solid bundled contact form plugin enables customers to make contact with you, and a means of displaying your business and location information prominently is also essential."
        btnText="Start today"
      />

      <InfoSectionLight
        image="/images/space.svg"
        id="discover"
        subtitle="Discover"
        title="Our Expert Team"
        text="Welcome to our expert team, where experienced minds unite to deliver tailored solutions. Embracing innovation as our driving force, we empower your success through strategic vision. Collaborative and client-centric, our dedicated experts are committed to realizing your business goals."
        btnText="Request a help"
        btnScrollTo="services"
      />

      <Services />

      <InfoSectionLight
        image="/images/design-notes.svg"
        id="#"
        subtitle="How We Can Help"
        title="Transforming Your Business"
        text="Welcome to our expert team, where experienced minds unite to deliver tailored IT solutions. Embracing innovation as our driving force, we empower your success through strategic vision. Collaborative and client-centric, our dedicated experts are committed to understanding your unique challenges and crafting custom solutions to address them effectively."
        // btnText="Read more"
      />

      <Footer />
    </>
  );
};

export default Home;
