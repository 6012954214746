import { styled } from "styled-components";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";

const Step3 = () => {
  return (
    <FormControl>
      <RadioGroup defaultValue="pdf-file" name="receive-type">
        <FormControlLabel
          value="pdf-file"
          defaultChecked
          control={<Radio />}
          label="PDF file sent to my email."
        />

        <FormControlLabel
          value="video-meeting"
          control={<Radio />}
          label="Video meeting."
        />

        <FormControlLabel
          value="audio-call"
          control={<Radio />}
          label="Audio call."
        />
      </RadioGroup>
    </FormControl>
  );
};

const Container = styled.div``;

export default Step3;
