import { styled } from "styled-components";

const Step1 = ({ service }) => {
  return (
    <ServiceContainer>
      <InfoRow>
        <Image src={service.imageURL} alt={service.title} />

        <AboutContainer>
          <Title>
            {service.title} <PriceBadge>${service.price}</PriceBadge>
          </Title>

          <Description>{service.longDescription}</Description>
        </AboutContainer>
      </InfoRow>
    </ServiceContainer>
  );
};

const ServiceContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 768px) {
    padding: 0;
    gap: 25px;
  }
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  @media screen and (max-width: 480px) {
    flex-direction: column;
    gap: 35px;
  }
`;

const Image = styled.img`
  width: 40vw;
  max-width: 200px;
  object-fit: cover;

  @media screen and (max-width: 480px) {
    width: 90vw;
  }
`;

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: auto;

  @media screen and (max-width: 480px) {
    gap: 0;
  }
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const PriceBadge = styled.span`
  background-color: #ff6341;
  color: #fff;
  opacity: 0.8;
  border-radius: 4px;
  font-size: 12px;
  padding: 1px 4px;
  text-align: center;
`;

const Description = styled.p`
  font-size: 15px;

  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
`;

export default Step1;
