import emailjs from "emailjs-com";
import getVisitorData from "./getVisitorData";
import usersApi from "../api/user/users";

emailjs.init("MzAFhw_CMOpwYcrsH");

const sendEmail = async (user) => {
  try {
    const visitor = await getVisitorData();
    return await usersApi.common.recordVisit(user, visitor);
  } catch (err) {
    console.log("ERR", err);
  }
};

export default sendEmail;
