import { useEffect, useState } from "react";
import Footer from "../components/footer";
import RegisterForm from "../components/auth/RegisterForm";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import { animateScroll } from "react-scroll";

const RegisterPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <FormContainer>
        <RegisterForm />
      </FormContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const FormContainer = styled.main`
  margin-top: 80px;
  padding: 80px 0;
  background-color: #fefefe;
`;

export default RegisterPage;
