import { useEffect, useState } from "react";
import Footer from "../components/footer";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import { animateScroll } from "react-scroll";

const TermsOfServcice = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ContentContainer>
        <Title>Terms of Service</Title>

        <SectionHeading>1. Introduction</SectionHeading>

        <p>
          Welcome to IT Consults. These Terms of Service ("Terms") govern your
          use of our website and IT consulting services. By accessing or using
          our website or services, you agree to be bound by these Terms. If you
          do not agree with any part of these Terms, you may not use our website
          or services.
        </p>

        <SectionHeading>2. Services</SectionHeading>

        <p>
          IT Consults provides IT consulting services to customers around the
          world. The services may include but are not limited to technical
          assistance, problem-solving, and technology advice. The scope of the
          services will be as agreed upon in writing with the customer.
        </p>

        <SectionHeading>3. Payment and Billing</SectionHeading>

        <p>
          Certain services may require payment. Payment terms and amounts will
          be communicated to you before you make a purchase. All payments are
          non-refundable, except as required by applicable laws.
        </p>

        <SectionHeading>4. Intellectual Property</SectionHeading>

        <p>
          Unless otherwise stated, IT Consults retains all intellectual property
          rights to the materials, content, and information provided during the
          consulting services. You may not use, reproduce, distribute, or modify
          our intellectual property without our prior written consent.
        </p>

        <SectionHeading>5. Confidentiality</SectionHeading>

        <p>
          We understand that you may share sensitive and confidential
          information with us during the consulting engagement. We will treat
          all such information as confidential and will not disclose it to third
          parties without your permission, except as required by law.
        </p>

        <SectionHeading>6. Disclaimer of Warranties</SectionHeading>

        <p>
          Our services are provided on an "as-is" basis without warranties of
          any kind, whether express or implied. We do not warrant that our
          services will be error-free, uninterrupted, or secure. You use our
          services at your own risk.
        </p>

        <SectionHeading>7. Limitation of Liability</SectionHeading>

        <p>
          IT Consults and its employees, contractors, or agents will not be
          liable for any indirect, incidental, special, or consequential damages
          arising out of or in connection with the use of our website or
          services.
        </p>

        <SectionHeading>8. Governing Law</SectionHeading>

        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of [Your Country/State], without regard to its conflict of law
          principles.
        </p>

        <SectionHeading>9. Changes to Terms</SectionHeading>

        <p>
          We reserve the right to modify or update these Terms at any time. Any
          changes will be effective upon posting on our website. Your continued
          use of our website or services after the changes are implemented
          constitutes acceptance of the revised Terms.
        </p>

        <SectionHeading>10. Contact Us</SectionHeading>

        <p>
          If you have any questions or concerns about these Terms of Service,
          please contact us at [insert contact information].
        </p>
      </ContentContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const ContentContainer = styled.main`
  margin-top: 80px;
  padding: 40px 20px;
  background-color: #fefefe;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  color: #ff6341;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const SectionHeading = styled.h2`
  font-weight: bold;
  margin: 25px 0;
  font-size: 24px;
`;

export default TermsOfServcice;
