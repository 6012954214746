const services = [
  {
    id: "rw8dc2l3fz9k",
    title: "Website Development Consultation",
    description:
      "Get expert advice for web development, design, and optimization. Choose tech, boost performance, secure.",
    longDescription:
      "Unlock your online potential with our Website Development Consultation. Our expert team will guide you through the process, from planning to implementation, ensuring your website is efficient, user-friendly, and tailored to meet your business objectives. Launch a captivating web presence that leaves a lasting impact on your audience.",
    price: 499,
    imageURL: "/images/website-dev.svg",
    features: [
      "Detailed analysis of your website requirements.",
      "Recommendations for the best technology stack.",
      "Responsive design suggestions.",
      "Performance optimization tips.",
      "Security best practices.",
    ],
  },
  {
    id: "p7v4x9h2j5q6",
    title: "Cloud Migration Strategy",
    description:
      "Seamless migration planning for your apps and infrastructure to the cloud. Optimize costs, ensure security, and minimize disruption.",
    longDescription:
      "Seamlessly migrate to the cloud with our expert guidance. Our Cloud Migration Strategy ensures a smooth transition, optimizing your infrastructure for enhanced scalability, security, and cost-efficiency. Unlock the full potential of cloud technology for your business success.",
    price: 799,
    imageURL: "/images/cloud.svg",
    features: [
      "Assessment of existing infrastructure and applications.",
      "Identification of cloud migration goals and objectives.",
      "Recommendation of suitable cloud providers (AWS, Azure, GCP, etc.).",
      "Cost estimation and optimization strategies.",
      "Security and compliance considerations.",
      "Migration plan with minimal disruption.",
    ],
  },
  {
    id: "m3t9g7k4c1b2",
    title: "Cybersecurity Assessment",
    description:
      "Comprehensive cybersecurity analysis. Identify vulnerabilities, protect data, and enhance your organization's security posture.",
    longDescription:
      "Shield your business from threats with our Cybersecurity Assessment. Our comprehensive evaluation identifies vulnerabilities and fortifies your defenses against potential breaches. Stay one step ahead of cyber threats and protect your valuable assets with our expert guidance.",
    price: 999,
    imageURL: "/images/cybersecurity.svg",
    features: [
      "Vulnerability scanning and penetration testing.",
      "Analysis of network security measures.",
      "Assessment of data protection and encryption.",
      "Identification of potential security breaches.",
      "Security awareness training for employees.",
      "Detailed security report and remediation plan.",
    ],
  },
  {
    id: "d6s1r9n8a5y2",
    title: "Data Analytics Strategy",
    description:
      "Unlock valuable insights with a tailored data analytics approach. Improve decision-making and optimize your analytics tools.",
    longDescription:
      "Unleash the power of data with our Data Analytics Strategy. Discover valuable insights and make informed decisions that drive business growth. Our expert team will help you harness the full potential of your data, transforming it into a strategic asset for your organization.",
    price: 599,
    imageURL: "/images/data-analytics.svg",
    features: [
      "Assessment of existing data infrastructure and analytics capabilities.",
      "Identification of business goals and key performance indicators (KPIs).",
      "Recommendation of suitable analytics tools (e.g., Power BI, Tableau).",
      "Data visualization and dashboard design guidance.",
      "Data quality and integrity assessment.",
      "Actionable insights for better decision-making.",
    ],
  },
  {
    id: "u2e5f4z7v1w3",
    title: "IT Infrastructure Audit",
    description:
      "Assess and enhance your IT infrastructure's performance and reliability. Identify weaknesses and implement optimizations.",
    longDescription:
      "Gain clarity and efficiency with our IT Infrastructure Audit. Our expert assessment identifies strengths and weaknesses in your infrastructure, enabling you to optimize resources, enhance performance, and ensure seamless operations. Stay at the forefront of technology with our tailored recommendations.",
    price: 899,
    imageURL: "/images/infrastructure.svg",
    features: [
      "Evaluation of hardware and server performance.",
      "Analysis of software versions and updates.",
      "Network bandwidth and security assessment.",
      "Identification of potential bottlenecks and single points of failure.",
      "Recommendations for infrastructure optimization.",
      "Disaster recovery and backup solutions review.",
    ],
  },
  {
    id: "l8q6o9i2p3k7",
    title: "Software Development Project Management",
    description:
      "Efficiently manage software projects from start to finish. Define requirements, allocate resources, and ensure successful delivery.",
    longDescription:
      "Elevate your software projects with our expert Project Management. From ideation to delivery, our team ensures efficient development, seamless collaboration, and timely execution. Stay on track and exceed expectations with our proven methodologies and dedicated support.",
    price: 699,
    imageURL: "/images/project-management.svg",
    features: [
      "Requirements gathering and scope definition.",
      "Project timeline and milestone planning.",
      "Resource allocation and task assignment.",
      "Risk assessment and mitigation planning.",
      "Communication and collaboration tools setup.",
      "Quality assurance and project delivery best practices.",
    ],
  },
];

export default services;
