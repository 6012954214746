import { styled } from "styled-components";
import { Link as RouterLink } from "react-router-dom";

export const ServicesContainer = styled.div`
  min-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #010606;
  padding: 50px 0;

  @media screen and (max-width: 768px) {
    min-height: 1100px;
  }

  @media screen and (max-width: 480px) {
    min-height: 1300px;
  }
`;

export const ServicesWrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;
  padding: 20px 50px;

  @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    padding: 20px 50px;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 20px 50px;
  }
`;

export const ServicesCard = styled.div`
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  height: 400px;
  max-height: 400px;
  padding: 30px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.02);
  }

  &:active {
    transform: scale(0.95);
  }
`;

export const ServicesIcon = styled.img`
  height: 120px;
  object-fit: contain;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
`;

export const ServicesH1 = styled.h1`
  font-size: 2.5rem;
  color: #fff;
  margin-bottom: 64px;

  @media screen and (max-width: 480px) {
    font-size: 2rem;
  }
`;

export const ServicesH2 = styled.h2`
  font-size: 1.2rem;
  margin-bottom: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1.4;
  width: 100%;
`;

export const ServicesP = styled.p`
  font-size: 14px;
  text-align: center;
`;

export const Price = styled.h3`
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ff6341;
  padding: 4px;
  padding-left: 8px;
  width: 80px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 15px;
  font-weight: 600;
  text-align: left;
  color: #fff;
`;

export const ViewDetailsBtn = styled(RouterLink)`
  text-decoration: none;
  text-transform: capitalize;
  color: #fff;
  background-color: #ff6341;
  width: 100%;
  padding: 10px 15px;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  margin-top: auto;
`;
