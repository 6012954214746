import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../components/footer";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import services from "../static/services";
import Spinner from "../components/spinner";
import { animateScroll } from "react-scroll";
import PaymentForm from "../components/paymentForm";

const ServiceRequest = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [service, setService] = useState(services[0]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    animateScroll.scrollToTop();

    const startDate = Date.now();

    const service = services.find((service) => service.id === params.serviceId);
    if (!service) {
      navigate("/not-found");
    } else {
      setService(service);
    }

    const endDate = Date.now();
    const timeElapsed = endDate - startDate;
    const splashTime = 3000 - timeElapsed;

    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, splashTime);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [params]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />

      <Navbar toggle={toggle} />

      <ContentContainer>
        {loading && (
          <SpinnerContainer>
            <Spinner size="large" />
          </SpinnerContainer>
        )}

        {!loading && (
          <PaymentContainer>
            <PaymentForm service={service} />
          </PaymentContainer>
        )}
      </ContentContainer>

      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const ContentContainer = styled.main`
  margin-top: 80px;
  padding: 50px 20px;
  padding-top: 0;
  background-color: #fefefe;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 40px;

  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 768px) {
    padding: 0;
    gap: 25px;
  }
`;

export default ServiceRequest;
