import { useEffect, useState } from "react";
import { styled } from "styled-components";
import { Step, StepLabel, Stepper } from "@mui/material";
import { animateScroll } from "react-scroll";
import StepContainer from "./StepContainer";
import invoicesApi from "../../api/user/invoices";
import Step1 from "./Step1";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { toast } from "react-toastify";
import Step2 from "./Step2";

const steps = ["Review", "Information", "Delivery", "Payment"];

const PaymentForm = ({ service }) => {
  const [activeStep, setActiveStep] = useState(1);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    invoicesApi
      .createInvoice(service.price, service.title)
      .then((res) => {
        const { clientSecret } = res.data;
        setClientSecret(clientSecret);
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message?.en || "Something went wrong.";
        toast.error(message);
      });
  }, []);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, [activeStep]);

  const handleNext = (e) => {
    try {
      const nextStep = activeStep + 1;
      if (nextStep <= steps.length) {
        setActiveStep(nextStep);
      }
    } catch (err) {}
  };

  const handlePrev = () => {
    try {
      const prevStep = activeStep - 1;
      if (prevStep >= 1) {
        setActiveStep(prevStep);
      }
    } catch (err) {}
  };

  return (
    <Container>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <StyledStep
            key={label}
            active={activeStep === index + 1}
            completed={activeStep > index + 1}
            onClick={() => setActiveStep(index + 1)}
          >
            <StepLabel>
              {label}{" "}
              {index === 1 && (
                <>
                  <br />
                  <span>(Optional)</span>
                </>
              )}{" "}
            </StepLabel>
          </StyledStep>
        ))}
      </Stepper>

      {activeStep === 1 && (
        <StepContainer
          title="Service Review"
          disablePrev={activeStep === 1}
          disableNext={activeStep === steps.length}
          lastStep={activeStep === steps.length}
          onNext={handleNext}
          onPrev={handlePrev}
        >
          <Step1 service={service} />
        </StepContainer>
      )}

      {activeStep === 2 && (
        <StepContainer
          title="If you need this service for an existing website or application, please provide us with the following information:"
          disablePrev={activeStep === 1}
          disableNext={activeStep === steps.length}
          lastStep={activeStep === steps.length}
          onNext={handleNext}
          onPrev={handlePrev}
        >
          <Step2 service={service} />
        </StepContainer>
      )}

      {activeStep === 3 && (
        <StepContainer
          title="How would you like to receive this service?"
          disablePrev={activeStep === 1}
          disableNext={activeStep === steps.length}
          lastStep={activeStep === steps.length}
          onNext={handleNext}
          onPrev={handlePrev}
        >
          <Step3 />
        </StepContainer>
      )}

      {activeStep === 4 && (
        <StepContainer
          title="Checkout"
          disablePrev={activeStep === 1}
          disableNext={activeStep === steps.length}
          lastStep={activeStep === steps.length}
          onNext={handleNext}
          onPrev={handlePrev}
        >
          <Step4 clientSecret={clientSecret} />
        </StepContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 3rem;
`;

const StyledStep = styled(Step)`
  cursor: pointer;

  svg {
    font-size: 40px !important;

    &.Mui-active,
    &.Mui-completed {
      fill: #ff6341 !important;
    }

    @media screen and (max-width: 480px) {
      font-size: 32px !important;
    }
  }

  .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
    margin: 0 15px !important;
    margin-top: 4px !important;
  }

  .MuiStepLabel-label {
    font-size: 15px !important;
    font-weight: 600 !important;

    span {
      font-size: 11px;
      font-weight: 600;
      color: #747474;
    }
  }
`;

export default PaymentForm;
