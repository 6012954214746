import { useEffect, useState } from "react";
import Footer from "../components/footer";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import { animateScroll } from "react-scroll";

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ContentContainer>
        <Title>Privacy Policy for IT Consults</Title>

        <Date>Effective Date: Jul, 07 2023</Date>

        <p>
          At IT Consults, we are committed to protecting your privacy and
          ensuring the security of your personal information. This Privacy
          Policy outlines how we collect, use, disclose, and store your
          information when you visit our website and use our IT consulting
          services. By accessing or using our website or services, you agree to
          the terms and practices described in this Privacy Policy.
        </p>

        <SectionHeading>1. Information We Collect</SectionHeading>

        <SectionSubheading>1.1 Personal Information</SectionSubheading>

        <p>
          When you visit our website or engage with our services, we may collect
          personal information that you voluntarily provide to us. This may
          include your name, email address, and any other information you
          provide during the consultation or registration process.
        </p>

        <SectionSubheading>1.2 Payment Information</SectionSubheading>

        <p>
          In order to process payments for our services, we may collect certain
          payment information, such as your credit card details, billing
          address, and other financial information. Please note that we do not
          store or retain your payment information. We utilize Stripe, a secure
          third-party payment processor, who adheres to strict industry
          standards for data protection.
        </p>

        <SectionSubheading>1.3 Usage Information</SectionSubheading>

        <p>
          We may collect information about how you use our website and services,
          including your IP address, browser type, operating system, referring
          URLs, pages viewed, and the dates and times of your visits. This
          information is collected through the use of cookies and similar
          tracking technologies. Please refer to our Cookie Policy for more
          details.
        </p>

        <SectionHeading>2. Use of Information</SectionHeading>

        <SectionSubheading>2.1 Providing Services</SectionSubheading>

        <p>
          We use the personal information you provide to deliver and improve our
          IT consulting services. This includes contacting you, responding to
          your inquiries, managing your account, and providing customer support.
        </p>

        <SectionSubheading>2.2 Payment Processing</SectionSubheading>

        <p>
          We use the payment information you provide to process payments for the
          services you request. Your payment information is securely transmitted
          to and processed by our trusted payment processor, Stripe.
        </p>

        <SectionSubheading>2.3 Communication</SectionSubheading>

        <p>
          We may use your contact information to send you important updates,
          notifications, newsletters, and marketing communications related to
          our services. You can opt out of these communications at any time by
          following the instructions provided in the communication.
        </p>

        <SectionSubheading>2.4 Legal Compliance</SectionSubheading>

        <p>
          We may use and disclose your personal information as required by
          applicable laws, regulations, legal processes, or governmental
          requests. We may also use your information to investigate and prevent
          fraud or other illegal activities.
        </p>

        <SectionHeading>3. Disclosure of Information</SectionHeading>

        <SectionSubheading>3.1 Third-Party Service Providers</SectionSubheading>

        <p>
          We may share your personal information with trusted third-party
          service providers who assist us in operating our website, delivering
          our services, and conducting our business. These providers are
          contractually obligated to protect your information and are prohibited
          from using your information for any other purpose.
        </p>

        <SectionSubheading>3.2 Business Transfers</SectionSubheading>

        <p>
          In the event of a merger, acquisition, or sale of all or a portion of
          our assets, your personal information may be transferred to the
          acquiring entity. We will notify you via email and/or a prominent
          notice on our website of any change in ownership or use of your
          personal information.
        </p>

        <SectionSubheading>3.3 Legal Requirements</SectionSubheading>

        <p>
          We may disclose your personal information if required to do so by law
          or in good faith belief that such action is necessary to (a) comply
          with a legal obligation, (b) protect and defend our rights or
          property, (c) prevent or investigate possible wrongdoing, or (d) act
          in urgent circumstances to protect the personal safety of users of our
          services or the public.
        </p>

        <SectionHeading>4. Data Retention</SectionHeading>

        <p>
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy, unless a longer
          retention period is required or permitted by law. After the retention
          period expires, we securely delete or anonymize your information.
        </p>

        <SectionHeading>5. Security</SectionHeading>

        <p>
          We implement appropriate technical and organizational measures to
          protect your personal information from unauthorized access, loss,
          misuse, alteration, or destruction. However, no method of transmission
          over the internet or electronic storage is completely secure, so we
          cannot guarantee absolute security.
        </p>

        <SectionHeading>6. Changes to the Privacy Policy</SectionHeading>

        <p>
          We reserve the right to modify or update this Privacy Policy at any
          time. We will notify you of any material changes by posting the
          updated Privacy Policy on our website and updating the effective date.
          Your continued use of our website or services after the changes are
          implemented will signify your acceptance of the revised Privacy
          Policy.
        </p>

        <SectionHeading>7. Contact Us</SectionHeading>

        <p>
          If you have any questions, concerns, or requests regarding this
          Privacy Policy or the handling of your personal information, please
          contact us at{" "}
          <a href="mailto:itconsults.co@gmail.com" target="_blank">
            our email
          </a>{" "}
          and we will response in just a few hours.
        </p>

        <p>
          Please review this Privacy Policy regularly to stay informed about our
          practices and any updates.
        </p>

        <SectionHeading>Payment Disclaimer</SectionHeading>

        <p>Payments made for our services are not refundable.</p>
      </ContentContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const ContentContainer = styled.main`
  margin-top: 80px;
  padding: 40px 20px;
  background-color: #fefefe;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  color: #ff6341;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const Date = styled.h2`
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 24px;

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

const SectionHeading = styled.h3`
  font-weight: bold;
  margin: 25px 0;
`;

const SectionSubheading = styled.h4`
  font-weight: 600;
  margin-top: 25px;

  &,
  & + p {
    margin-left: 15px;
  }
`;

export default PrivacyPolicy;
