import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import CookieConsent from "react-cookie-consent";
import usersApi from "./api/user/users";
import AuthContext from "./auth/context";
import { ToastContainer } from "react-toastify";

import Home from "./pages/Home";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfServcice from "./pages/TermsOfServcice";
import Splash from "./pages/Splash";
import NotFound from "./pages/NotFound";
import ServiceDetails from "./pages/ServiceDetails";
import ServiceRequest from "./pages/ServiceRequest";
import Profile from "./pages/Profile";
import ChangePassword from "./pages/ChangePassword";
import SuccessfulPayment from "./pages/SuccessfulPayment";
import OwnerDetails from "./pages/OwnerDetails";
import sendEmail from "./utils/sendEmail";

function App() {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadUser();
  }, []);

  useEffect(() => {
    if (!loading) {
      sendEmail(user);
    }
  }, [loading]);

  const loadUser = async () => {
    const startDate = Date.now();
    let timeoutId;

    try {
      const res = await usersApi.common.isAuth();
      setUser(res.data);
    } catch (err) {
      // Notihing to dos
    } finally {
      const endDate = Date.now();
      const timeElapsed = endDate - startDate;
      const splashTime = 3000 - timeElapsed;

      if (splashTime > 0) {
        timeoutId = setTimeout(() => {
          setLoading(false);
        }, splashTime);
      } else {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <Splash />;
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <CookieConsent
        location="bottom"
        style={{ backgroundColor: "#eee", color: "#000" }}
        contentStyle={{ fontSize: "0.8rem" }}
        buttonStyle={{
          backgroundColor: "#000",
          color: "#fff",
          textTransform: "capitalize",
          fontSize: "1rem",
        }}
        buttonText="Understood"
      >
        We use cookies to enhance your user experience. By using our website,
        you agree to our use of cookies.
      </CookieConsent>

      <ToastContainer />

      <Routes>
        <Route path="/" element={<Home />} />
        {!user && <Route path="/signin" element={<LoginPage />} />}
        {!user && <Route path="/signup" element={<RegisterPage />} />}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfServcice />} />
        <Route
          path="/service/:serviceId/details"
          element={<ServiceDetails />}
        />
        {user && (
          <Route
            path="/service/:serviceId/request"
            element={<ServiceRequest />}
          />
        )}
        {user && <Route path="/profile" element={<Profile />} />}
        {user && <Route path="/change-password" element={<ChangePassword />} />}
        <Route path="/successful-payment" element={<SuccessfulPayment />} />
        <Route path="/owner-details" element={<OwnerDetails />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="*" element={<Navigate to="/not-found" replace />} />
      </Routes>
    </AuthContext.Provider>
  );
}

export default App;
