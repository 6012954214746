import client from "../client";
import authStorage from "../../auth/storage";

const createInvoice = async (amount, title) => {
  const config = { headers: { Authorization: authStorage.getToken() } };
  return await client.post("/invoices/create", { amount, title }, config);
};

const getMyInvoices = async () => {
  const cacheMins = 0;
  const config = { headers: { Authorization: authStorage.getToken() } };
  return await client.get("/invoices/my", config, cacheMins);
};

const functions = {
  createInvoice,
  getMyInvoices,
};

export default functions;
