import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { styled } from "styled-components";
import useAuth from "../../auth/useAuth";

const CheckoutForm = () => {
  const { user } = useAuth();
  const stripe = useStripe();
  const elements = useElements();

  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        receipt_email: user.email,
        return_url: window.origin + "/successful-payment",
      },
    });

    toast.error(error.message);
    // if (error.type === "card_error" || error.type === "validation_error") {
    //   toast.error(error.message);
    // } else {
    //   toast.error("An unexpected error occured.");
    // }

    setIsProcessing(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <Button disabled={isProcessing || !stripe || !elements} type="submit">
        {isProcessing ? "Processing ... " : "Pay Now"}
      </Button>
    </form>
  );
};

const Button = styled.button`
  border-radius: 8px;
  background-color: ${({ disabled }) => (disabled ? "#747474" : "#ff6341")};
  white-space: nowrap;
  padding: 8px 25px;
  width: 120px;
  color: #fff;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 600;
  margin-top: 25px;
  margin-bottom: -10px;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;

  &:active {
    transform: scale(0.95);
  }
`;

export default CheckoutForm;
