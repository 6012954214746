import { styled } from "styled-components";

const Spinner = ({ size = "medium" }) => {
  const getSize = () => {
    try {
      switch (size) {
        case "small":
          return "2em";

        case "medium":
          return "3.5em";

        case "large":
          return "5em";
      }
    } catch (err) {
      return "2em";
    }
  };

  return (
    <Container size={getSize()}>
      <SpinnerPin />
    </Container>
  );
};

const Container = styled.div`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  cursor: not-allowed;
  border-radius: 50%;
  border: 2px solid #444;
  box-shadow: -10px -10px 10px #6359f8, 0px -10px 10px 0px #9c32e2,
    10px -10px 10px #f36896, 10px 0 10px #ff0b0b, 10px 10px 10px 0px#ff5500,
    0 10px 10px 0px #ff9500, -10px 10px 10px 0px #ffb700;
  animation: rot55 0.7s linear infinite;

  @keyframes rot55 {
    to {
      transform: rotate(360deg);
    }
  }
`;

const SpinnerPin = styled.div`
  border: 2px solid #444;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default Spinner;
