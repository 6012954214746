import { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavBtn,
  NavBtnLink,
  NavRouterLink,
  NavbarAvatar,
  SubMenuWrapper,
  SubMenu,
  UserInfo,
  UserName,
  ProfileContainer,
  UserAvatar,
  HorizontalLine,
  SubMenuLink,
  SubMenuTitle,
} from "./NavbarElements";
import { BiSolidUser, BiLogOutCircle } from "react-icons/bi";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { RiLockPasswordFill } from "react-icons/ri";
import "./../../App.css";
import useAuth from "../../auth/useAuth";
import { toast } from "react-toastify";

export const Navbar = ({ toggle }) => {
  const { user, logout } = useAuth();
  const [scrollNav, setScrollNav] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollNav(window.scrollY >= 80);
    });
  }, []);

  const handleToggleSubMenu = () => {
    try {
      setShowSubMenu(!showSubMenu);
    } catch (err) {}
  };

  const handleLogout = () => {
    try {
      logout();
      toast.warn("You are logged out", { position: "top-left" });
    } catch (err) {}
  };

  return (
    <>
      <Nav scrollnav={scrollNav ? "true" : ""}>
        <NavbarContainer>
          <NavLogo
            to="/"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <h1 className="logo-text">IT Consults</h1>
          </NavLogo>

          <NavMenu>
            <NavItem>
              <NavLinks href="/" to="about">
                About
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks href="/" to="discover">
                Discover
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavLinks href="/" to="services">
                Services
              </NavLinks>
            </NavItem>

            <NavItem>
              <NavRouterLink to="/privacy-policy">Privacy Policy</NavRouterLink>
            </NavItem>

            <NavItem>
              <NavRouterLink to="/terms">Terms of Service</NavRouterLink>
            </NavItem>
          </NavMenu>

          {!user && (
            <NavBtn>
              <NavBtnLink to="/signin">Sign In</NavBtnLink>
            </NavBtn>
          )}

          <ProfileContainer>
            {!!user && (
              <>
                <NavbarAvatar
                  src={user.avatarURL || "/images/default-user.jpg"}
                  alt={`${user.name}'s profile picture`}
                  onClick={handleToggleSubMenu}
                />

                {showSubMenu && (
                  <SubMenuWrapper>
                    <SubMenu>
                      <UserInfo>
                        <UserAvatar
                          src={user.avatarURL || "/images/default-user.jpg"}
                          alt={`${user.name}'s profile picture`}
                        />

                        <UserName>{user.name}</UserName>
                      </UserInfo>

                      <HorizontalLine />

                      <SubMenuLink to="/profile">
                        <BiSolidUser size={24} />
                        <SubMenuTitle>Edit Profile</SubMenuTitle>
                        <MdOutlineKeyboardArrowRight size={24} />
                      </SubMenuLink>

                      <SubMenuLink to="/change-password">
                        <RiLockPasswordFill size={24} />
                        <SubMenuTitle>Change Password</SubMenuTitle>
                        <MdOutlineKeyboardArrowRight size={24} />
                      </SubMenuLink>

                      <SubMenuLink to="/" onClick={handleLogout}>
                        <BiLogOutCircle size={24} />
                        <SubMenuTitle>Logout</SubMenuTitle>
                        <MdOutlineKeyboardArrowRight size={24} />
                      </SubMenuLink>
                    </SubMenu>
                  </SubMenuWrapper>
                )}
              </>
            )}

            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
          </ProfileContainer>
        </NavbarContainer>
      </Nav>
    </>
  );
};
