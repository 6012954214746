import { styled } from "styled-components";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";

const StepContainer = ({
  title,
  onNext,
  onPrev,
  disablePrev,
  disableNext,
  children,
}) => {
  return (
    <Container>
      <StepTitle>{title}</StepTitle>

      <ContentContainer>{children}</ContentContainer>

      <ButtonsContainer>
        <Button direction="left" onClick={onPrev} disabled={disablePrev}>
          <MdKeyboardDoubleArrowLeft size={24} />
          <ButtonText>Back</ButtonText>
        </Button>

        <Button direction="right" onClick={onNext} disabled={disableNext}>
          <ButtonText>Next</ButtonText>
          <MdKeyboardDoubleArrowRight size={24} />
        </Button>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 50px;
  border: 2px solid #ff6341;
  border-radius: 8px;
  padding: 15px 20px;

  display: flex;
  flex-direction: column;
  gap: 35px;

  @media screen and (max-width: 480px) {
    border: 1.5px solid #ff6341;
    gap: 15px;
  }
`;

const StepTitle = styled.h1`
  font-size: 18px;
  font-weight: 600;
  line-height: 1.4;
`;

const ContentContainer = styled.div`
  margin-bottom: 10px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const Button = styled.button`
  border-radius: 8px;
  background-color: ${({ disabled }) => (disabled ? "#747474" : "#ff6341")};
  white-space: nowrap;
  padding: 8px 25px;
  width: 120px;
  color: #fff;
  font-size: 14px;
  outline: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  font-weight: 600;

  display: flex;
  justify-content: ${({ direction }) =>
    direction === "left" ? "flex-start" : "flex-end"};
  align-items: center;
  gap: 4px;

  &:active {
    transform: scale(0.95);
  }
`;

const ButtonText = styled.span``;

export default StepContainer;
