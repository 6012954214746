import {
  FooterContainer,
  FooterH2,
  FooterLink,
  FooterWrapper,
  FooterRouterLink,
  FooterScrollLink,
} from "./FooterElements";
import "./../../App.css";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrapper className="container py-5">
        <div className="row gx-5 px-4 gy-4">
          <div className="col-lg-3 col-sm-6">
            <FooterH2>About Us</FooterH2>
            <FooterScrollLink href="/" to="about">
              About
            </FooterScrollLink>

            <FooterRouterLink to="/privacy-policy">
              Privacy Policy
            </FooterRouterLink>

            <FooterRouterLink to="/terms">Terms of Service</FooterRouterLink>
          </div>

          <div className="col-lg-3 col-sm-6">
            <FooterH2>Contact Us</FooterH2>
            <FooterLink href="mailto:itconsults.co@gmail.com" target="_blank">
              Contact
            </FooterLink>

            <FooterLink href="mailto:itconsults.co@gmail.com" target="_blank">
              Support
            </FooterLink>
          </div>

          <div className="col-lg-3 col-sm-6">
            <FooterH2>Customers</FooterH2>
            <FooterLink href="#">Our Customers</FooterLink>
            <FooterLink href="#">How to reach us</FooterLink>
            <FooterLink href="#">Video Samples</FooterLink>
            <FooterLink href="#">Agency</FooterLink>
          </div>

          <div className="col-lg-3 col-sm-6">
            <FooterH2>Follow Us</FooterH2>

            <FooterLink
              target="_blank"
              href="https://instagram.com/itconsults.co"
            >
              Instagram
            </FooterLink>

            <FooterLink
              target="_blank"
              href="https://twitter.com/itconsults_co"
            >
              Twitter
            </FooterLink>

            <FooterLink
              target="_blank"
              href="https://www.facebook.com/profile.php?id=61551001947178"
            >
              Facebook
            </FooterLink>

            {/* <FooterLink
              target="_blank"
              href="https://www.linkedin.com/in/consultaira-limited-6425b1283/"
            >
              LinkedIn
            </FooterLink> */}
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-5 ">
            <h1 className="copytight gray-text center-align">
              Copyright &copy; {new Date().getFullYear()} IT Consults Ltd.{" "}
              <br /> All rights reserved.
            </h1>
          </div>
        </div>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
