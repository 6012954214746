import { styled } from "styled-components";

const OwnerDetails = () => {
  return (
    <>
      <Heading>Website's Owner:</Heading>
      <SubHeading>
        Name: <NonBold>Mohammed Shaqfa</NonBold>
      </SubHeading>
      <SubHeading>
        Email: <NonBold>moh.kh.sh2001@gmail.com</NonBold>
      </SubHeading>
      <SubHeading>
        Phone Number: <NonBold>+905317710870</NonBold>
      </SubHeading>
    </>
  );
};

const Heading = styled.h1`
  font-size: 3rem;
  font-weight: 800;
`;

const SubHeading = styled.h2`
  font-size: 24px;
  font-weight: bold;
`;

const NonBold = styled.span`
  font-weight: 500;
`;

export default OwnerDetails;
