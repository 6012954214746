import { useEffect, useState } from "react";
import Footer from "../components/footer";
import { Navbar } from "../components/navbar";
import { Sidebar } from "../components/sidebar";
import { styled } from "styled-components";
import { animateScroll } from "react-scroll";

const SuccessfulPayment = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    animateScroll.scrollToTop();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Container>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ContentContainer>
        <Title>Privacy Policy for IT Consults</Title>
      </ContentContainer>
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  background-color: #000;
`;

const ContentContainer = styled.main`
  margin-top: 80px;
  padding: 40px 20px;
  background-color: #fefefe;
`;

const Title = styled.h1`
  margin-bottom: 40px;
  color: #ff6341;
  font-weight: bold;

  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

export default SuccessfulPayment;
