import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBJeAIhm8P_RTTohktgYWQ2-1tS8-VWBNs",
  authDomain: "it-consults-experts.firebaseapp.com",
  projectId: "it-consults-experts",
  storageBucket: "it-consults-experts.appspot.com",
  messagingSenderId: "455793562612",
  appId: "1:455793562612:web:f6fa5b24466922bec0dfa1",
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
