import { useState } from "react";
import {
  HeroContainer,
  HeroBg,
  VideoBg,
  HeroContent,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from "./HeroElements";
import { RouterButton, ScrollButton } from "../ButtonElements";
import "../../App.css";
import useAuth from "../../auth/useAuth";

const HeroSection = () => {
  const { user } = useAuth();
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };

  return (
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src="/videos/bgvideo.mp4" />
      </HeroBg>

      <HeroContent>
        <h1 className="h1-hero">
          Empowering Your Business Through IT Consulting Excellence
        </h1>

        <h2 className="hero-text">
          Unlock the Full Potential of Technology for Optimal Growth and Success
        </h2>

        <HeroBtnWrapper>
          {!user ? (
            <RouterButton
              to="/signin"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Get started {hover ? <ArrowForward /> : <ArrowRight />}
            </RouterButton>
          ) : (
            <ScrollButton
              href="/"
              to="services"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Get started {hover ? <ArrowForward /> : <ArrowRight />}
            </ScrollButton>
          )}
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default HeroSection;
