import { styled } from "styled-components";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <Container>
      <Image src="/images/404.svg" alt="Not Found" />

      <MessageContainer>
        <Title>Oops! 😬</Title>

        <Paragraph>We couldn't find the page you're looking for.</Paragraph>

        <Paragraph>
          Please check the URL or return to our <Link to="/">home page</Link>.
        </Paragraph>
      </MessageContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 45px;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const Image = styled.img`
  width: 80vw;
  max-width: 250px;
  object-fit: contain;
`;

const MessageContainer = styled.p`
  width: 90vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  color: #000;
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
`;

const Paragraph = styled.p`
  font-size: 22px;
  font-weight: 600;

  a {
    color: #ff6341;
  }
`;

export default NotFound;
