import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import Spinner from "../spinner";
import paymentApi from "../../api/user/payment";
import { toast } from "react-toastify";

const Step4 = ({ clientSecret }) => {
  const [publishableKey, setPublishableKey] = useState(null);

  useEffect(() => {
    paymentApi
      .getStripePublishableKey()
      .then((res) => {
        const { publishableKey } = res.data;
        setPublishableKey(loadStripe(publishableKey));
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message?.en || "Something went wrong.";
        toast.error(message);
      });
  }, []);

  if (!clientSecret || !publishableKey) {
    return <Spinner size="medium" />;
  }

  return (
    <Elements stripe={publishableKey} options={{ clientSecret }}>
      <CheckoutForm />
    </Elements>
  );
};

export default Step4;
