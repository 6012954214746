import client from "../client";
import authStorage from "../../auth/storage";

////////////////////////////// Common APIs //////////////////////////////
const isAuth = async () => {
  const bearerToken = authStorage.getToken();
  if (!bearerToken) throw new Error("");
  const config = { headers: { Authorization: bearerToken } };
  const cacheMins = 0;
  return await client.get("/users/authenticate", config, cacheMins);
};

const recordVisit = async (user, visitor) => {
  return await client.post("/users/record-visit", { user, visitor });
};

const updateProfile = async (profileData) => {
  const formData = new FormData();
  for (let key in profileData) {
    formData.append(key, profileData[key]);
  }

  const config = {
    headers: {
      Authorization: authStorage.getToken(),
      "Content-Type": "multipart/form-data",
    },
  };

  return await client.patch("/users/profile/update", formData, config);
};

const getForgotPasswordCode = async (lang, sendTo, email) => {
  const cacheMins = 0;
  return await client.get(
    `/users/password/forgot?email=${email}&lang=${lang}&sendTo=${sendTo}`,
    {},
    cacheMins
  );
};

const resetPassword = async (email, code, newPassword) => {
  return await client.post("/users/password/forgot", {
    email,
    code,
    newPassword,
  });
};

const changePassword = async (oldPassword, newPassword, confirmPassword) => {
  const config = { headers: { Authorization: authStorage.getToken() } };
  const body = { oldPassword, newPassword, confirmPassword };
  return await client.patch("/users/password/change", body, config);
};

const verify = async (subject, code) => {
  const config = { headers: { Authorization: authStorage.getToken() } };
  return await client.post(`/users/verify/${subject}`, { code }, config);
};

const resendVerificationCode = async (subject, lang) => {
  const cacheMins = 0;
  const config = { headers: { Authorization: authStorage.getToken() } };
  return await client.get(
    `/users/verify/${subject}?lang=${lang}`,
    config,
    cacheMins
  );
};

const seeNotifications = async () => {
  const cacheMins = 0;
  const config = { headers: { Authorization: authStorage.getToken() } };
  return await client.get("/users/notifications/see", config, cacheMins);
};

const requestAccountDeletion = async () => {
  const cacheMins = 10;
  const config = { headers: { Authorization: authStorage.getToken() } };
  return await client.get("/users/account/deletion/request", config, cacheMins);
};

const functions = {
  common: {
    isAuth,
    recordVisit,
    updateProfile,
    getForgotPasswordCode,
    resetPassword,
    changePassword,
    verify,
    resendVerificationCode,
    seeNotifications,
    requestAccountDeletion,
  },
  office: {},
  admin: {},
};

export default functions;
